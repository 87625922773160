const hostName = window.location.hostname
const baseURL = `https://${hostName}${hostName === 'localhost' ? ':5173' : ''}`

export default {
    authorityUrl: 'https://openidserverprod.azurewebsites.net',
    clientId: import.meta.env.VITE_LOGIN_CLIENT,
    postLogoutRedirectUri: baseURL,
    redirectUri: `${baseURL}/callback.html`,
    silentRedirectUri: `${baseURL}/silent-renew.html`,
}
