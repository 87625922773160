import { UserManager, WebStorageStateStore, User } from 'oidc-client-ts'

import appConfig from '@/app.config'

export default class AuthService {
    private userManager: UserManager
    private static instance: AuthService

    private constructor() {
        const settings: any = {
            accessTokenExpiringNotificationTimeInSeconds: 60,
            authority: appConfig.authorityUrl,
            automaticSilentRenew: true,
            client_id: appConfig.clientId,
            post_logout_redirect_uri: appConfig.postLogoutRedirectUri,
            redirect_uri: appConfig.redirectUri,
            response_type: 'code',
            scope: 'openid profile',
            silent_redirect_uri: appConfig.silentRedirectUri,
            userStore: new WebStorageStateStore({ store: window.localStorage }),
            loadUserInfo: true,
        }
        this.userManager = new UserManager(settings)
        this.userManager.events.addAccessTokenExpiring(async () => {})
    }

    public static getInstance(): AuthService {
        return AuthService.instance || (AuthService.instance = new AuthService())
    }

    public getAccessToken(): Promise<string> {
        return this.userManager.getUser().then((user: User | null) => user?.access_token || '')
    }

    public getUser(): Promise<User | null> {
        return this.userManager.getUser()
    }

    public login(): Promise<void> {
        return this.userManager.signinRedirect()
    }

    public async logout(url?: string): Promise<void> {
        for (let key in localStorage) localStorage.removeItem(key)
        for (let key in sessionStorage) sessionStorage.removeItem(key)
        if (url) {
            window.location.replace(url)
        } else {
            this.userManager.signoutRedirect()
        }
    }

    public signinCallback(): Promise<void | User> {
        return this.userManager.signinCallback()
    }

    public signinRedirectCallback(): Promise<User> {
        return this.userManager.signinRedirectCallback()
    }

    public signinSilentCallback(): Promise<void | User> {
        return this.userManager.signinSilentCallback()
    }
}
